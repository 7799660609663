@media screen and (min-width: 992px) {
  .ui.grid + .grid {
    margin-top: 17px;
  }
}

@media screen and (max-width: 500px) {
  .listing {
    border: solid 1px grey;
    border-radius: 3%;
  }
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

.header-logo {
  max-height: 75px;
  font-family: 'Calibri', 'Trebuchet MS', sans-serif;
}

body {
  background-color: rgb(00, 00, 00);
  font-family: sans-serif;
}

.top-text {
  font-size: 75%;
  color: white;
}

.main-text {
  font-size: 75%;
  color: white;
}

.top-icon-image {
  max-height: 20px;
}

.top-icon-text {
  font-size: 75%;
  color: white;
}

.game-image {
  max-height: 100%;
  width: auto;
}

.ui[class*='right floated'].button {
  background-color: black;
  color: grey;
  font-size: 12px;
}

.title {
  font-size: 17px;
  color: white;
  font-weight: bold;
}
.gameText {
  color: white;
}

.offer {
  font-size: 17px;
  color: white;
  font-weight: bold;
}

.terms {
  font-size: 10px;
  margin: 10px;
  color: grey;
  font-weight: bold;
}

.comment {
  font-size: 17px;
  color: white;
  font-weight: bold;
  font-style: italic;
}

.bottom-text {
  margin: 5% 10% 10% 10%;
  font-size: 17px;
  color: white;
}

.footer-text {
  margin: 5% 10% 10% 10%;
  font-size: 15px;
  color: white;
}

.footer-image {
  margin: 5% 10% 10% 10%;
  width: 100%;
}
.bottom-text {
  margin: 5% 10% 10% 10%;
  font-size: 17px;
  color: white;
}

.cookie-policy {
  margin: 5% 10% 10% 10%;
  font-size: 17px;
  color: white;
  text-align: justify;
}

.privacy-policy {
  margin: 5% 10% 10% 10%;
  font-size: 17px;
  color: white;
  text-align: justify;
}

.terms-policy {
  margin: 5% 10% 10% 10%;
  font-size: 17px;
  color: white;
  text-align: justify;
}

.listing {
  border: solid 1px grey;
}

.title-row {
  border: solid 1px grey;
}
